import React, { Component } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';
import * as Yup from "yup";
import YupPassword from 'yup-password'
YupPassword(Yup) // extend yup

const API_URL = process.env.REACT_APP_API_URL;

const schema = Yup.object().shape({
  email: Yup.string()
    .required("Email is a required field")
    .email("Invalid email format"),
  password: Yup.string()
    .required("Password is a required field"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
});

class SignupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginStatus: false,
      user: null,
    };
  };

  loginRequest(email, password) {
    console.log("sending to: ", API_URL)
    axios.post(API_URL + 'auth/login', {email,password}, {withCredentials: true}).then(
      (response) => {
        // Success
        console.log(response)
        this.props.onSuccess(response.data.message);
      },
      (error) => {
        // There was an error
        this.props.onFailure(error.response.data.message)
      }
    ).catch((error)=>{
      console.log("actual error: ", error)
      this.props.onFailure("unknown error, " + error.message)
    });
  }

  signupRequest(email, password) {
    console.log("Starting post of signup")
    console.log("sending to: ", API_URL)

    axios.post(API_URL + 'auth/signup', {email,password}).then(
      (response) => {
        // Success
        console.log("Got signup response")
        
        // Now login
        this.loginRequest(email, password)
      },
      (error) => {
        // There was an error
        console.log("error signing up = ", error.response)
        if (error.response.data && error.response.data.message) {
            this.props.onFailure(error.response.data.message)
        } else {
          this.props.onFailure("error signing up")
        }
      }
    ).catch((error)=>{
      console.log("actual error: ", error)
      this.props.onFailure("unknown error, " + error.message)
    });

    console.log("Finished post of signup")
  }

  render() {

    return (
      <div>
        <Formik
         initialValues={{ email: '', password: '' }}
         validationSchema={schema}

         onSubmit={(values, { setSubmitting }) => {
           this.signupRequest(values.email, values.password)
           setSubmitting(false);
         }}
      >
        {({ isSubmitting }) => (
          <Form>
            <label for="email">Email</label>
            <Field type="email" name="email" />
            <ErrorMessage name="email" component="div" class="error"/>
            <label for="password">Password</label>
            <Field type="password" name="password" />
            <ErrorMessage name="password" component="div" class="error"/>
            <label for="passwordConfirmation">Re-type password</label>
            <Field type="password" name="passwordConfirmation" />
            <ErrorMessage name="passwordConfirmation" component="div" class="error"/>
            <button type="submit" disabled={isSubmitting}>
              Submit
            </button>
          </Form>
          )}
        </Formik>
        </div>
    );
  }
}

export default SignupForm;
