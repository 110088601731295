import React, { Component } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';
import * as Yup from "yup";
import YupPassword from 'yup-password'
YupPassword(Yup) // extend yup

const API_URL = process.env.REACT_APP_API_URL;

const schema = Yup.object().shape({
  email: Yup.string()
    .required("Email is a required field")
    .email("Invalid email format"),
  password: Yup.string()
    .required("Password is a required field")
});

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginStatus: false,
      user: null,
    };
  };

  loginRequest(email, password) {
    console.log("sending to: ", API_URL)
    axios.post(API_URL + 'auth/login', {email,password}, {withCredentials: true}).then(
      (response) => {
        // Success
        console.log(response)
        this.props.onSuccess(response.data.message);
      },
      (error) => {
        // There was an error
        this.props.onFailure(error.response.data.message)
      }
    ).catch((error)=>{
      console.log("actual error: ", error)
      this.props.onFailure("unknown error, " + error.message)
    });
  }

  render() {

    return (
      <div>
        <Formik
         initialValues={{ email: '', password: '' }}
         validationSchema={schema}

         onSubmit={(values, { setSubmitting }) => {
           this.loginRequest(values.email, values.password)
           setSubmitting(false);
         }}
        >
        {({ isSubmitting }) => (
          <Form>
            <label for="email">Email</label>
            <Field type="email" name="email" />
            <ErrorMessage name="email" component="div" class="error"/>
            <label for="password">Password</label>
            <Field type="password" name="password" />
            <ErrorMessage name="password" component="div" class="error"/>
            <button type="submit" disabled={isSubmitting}>Submit</button>
          </Form>
          )}
        </Formik>
        </div>
    );
  }
}

export default LoginForm;
