import React from 'react';
import ButtonAbout from './Images/ButtonAbout2.jpg';
import ButtonDIY from './Images/ButtonDIY2.jpg';
import ButtonDirections from './Images/ButtonDirections2.jpg';
import ButtonRSVP from './Images/ButtonRSVP2.jpg';
import ButtonFAQ from './Images/ButtonFAQ2.jpg';

function Menu () {

  return (
    <div class="menu center-text">
      <a href="#About"><img src={ButtonAbout} class="menu-button" alt="Cartoon Button saying &quot;About&quot;"/></a>
      <a href="#DIY"><img src={ButtonDIY} class="menu-button" alt="Cartoon Button saying &quot;DIY&quot;"/></a>
      <a href="#Directions"><img src={ButtonDirections} class="menu-button" alt="Cartoon Button saying &quot;Directions&quot;"/></a>
      <a href="#RSVP"><img src={ButtonRSVP} class="menu-button" alt="Cartoon Button saying &quot;RSVP&quot;"/></a>
      <a href="#FAQ"><img src={ButtonFAQ} class="menu-button" alt="Cartoon Button saying &quot;FAQ&quot;"/></a>
    </div>
  )
}

export default Menu;
