import React from 'react';
import BackToTop from './BackToTop';
import ThingsWeDontLike from './Images/Things_we_dont_like.jpg';
import Camping from './Images/Camping.jpg';
import NoCurfew from './Images/No_curfew.jpg';
import Party from './Images/Party.jpg';
import OpenBar from './Images/Open_bar.jpg';
import Time from './Images/Time.jpg';
import NoRules from './Images/No_rules.jpg';
import TimeNote from './Images/TimeNote.jpg';
import ButtonAbout from './Images/ButtonAbout2.jpg';

function About () {

  return (
    <div>
      <h2 class="center-text" id="About"><img src={ButtonAbout} class="subheader" alt="Cartoon Button saying &quot;About&quot;"/></h2>
      <div><img src={ThingsWeDontLike} class="center" alt="Cartoon saying &quot;Things we don't like about weddings&quot;. Arrow points from &quot;In the bin!&quot; to a bin which Durbin is putting Speeches and Awkward First Dance in"/></div>
      <div><img src={Camping} class="center" alt="Cartoon saying &quot;Things we love about Ash Barton&quot;. Next to this text is Zoe presenting. There is a drawing of a tent with the text &quot;On site camping&quot;"/></div>
      <div><img src={NoCurfew} class="center" alt="Cartoon of the text &quot;No curfew!&quot; where the o is a clock. Also in the drawing is zoe in a red and white pool ring and the text &quot;Swimming pool&quot;"/></div>
      <div><img src={Party} class="center" alt="Cartoon of Zoe and Durbin atop a speaker, Durbin is in Black Metal makeup doing the claw and Zoe is in a T-Shirt that says &quot;80's&quot;. Many music notes and graphical signs indicating music are coming out of the speaker. Undernearth the speaker is the text &quot;Different spaces to party&quot;"/></div>
      <div><img src={OpenBar} class="center" alt="Cartoon of Zoe and Durbin looking rather drunk with the text &quot;Open bar! (Bring a back up bottle, you booze hounds!)&quot;"/></div>
      <div><img src={Time} class="center" alt="Cartoon showing a calender with the text &quot;Time! Our ceremony & party will be on the 15th. But we have the venue from midday 14th till 10am 17th. Come & join us for a holiday by the coast!&quot;"/></div>
      <div><img src={TimeNote} class="center" alt="Cartoon note saying &quot;Please note, our wedding year is 2025&quot;"/></div>
      <div><img src={NoRules} class="center" alt="Cartoon saying &quot;No rules!&quot; underlined in red followed by the text &quot;In the words of Bill & Ted&quot; followed by a drawing of Bill & Ted then the text &quot;Be excellent to each other!&quot;"/></div>
      <p class="center-text" ><a href="https://www.ashbarton.com/">Please check out the venue website</a></p>
      <BackToTop/>
    </div>
  )
}

export default About;
