import React from 'react';
import { useState, useEffect } from 'react';

import DashboardForm from './DashboardForm';

function Dashboard () {

  const [authenticatedUser, setAuthenticatedUser] = useState(null);

  useEffect(() => {
    setAuthenticatedUser(JSON.parse(localStorage.getItem("authenticateduser")))
  }, [])

  return (
    <div>
      {authenticatedUser && <DashboardForm />}
    </div>
  )
}

export default Dashboard;
