import React from 'react';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";

import LoginForm from './LoginForm';
import SignupForm from './SignupForm';

import TabGroup from './TabGroup';
import Tab from './Tab';

function LoginPage () {

  const navigate = useNavigate();

  // Get the initial values from the existing localStorage value otherwise if we refresh the page we lose our state
  const [authenticated, setAuthenticated] = useState(JSON.parse(localStorage.getItem("authenticated")));
  const [authenticatedUser, setAuthenticatedUser] = useState(JSON.parse(localStorage.getItem("authenticateduser")));
  const [signupStatus, setSignupStatus] = useState("");
  const [loginStatus, setLoginStatus] = useState("");
  const { state } = useLocation();

  useEffect(() => {
    console.log('LoginPage - use effect - authenticated: ', authenticated)
    if (authenticated) {
      localStorage.setItem("authenticated", true);
      localStorage.setItem("authenticateduser", JSON.stringify(authenticatedUser));

      // Redirect to the state.path or dashboard if state.path is undefined
      navigate(state?.path || "/dashboard");
    } else {
      localStorage.setItem("authenticated", false);
      localStorage.setItem("authenticateduser", null);
    }
  }, [authenticated, navigate, authenticatedUser, state])

  function LoginSuccess(user) {
    setAuthenticated(true)
    setAuthenticatedUser(user)
  }

  function LoginFailure(errorMessage) {
    setLoginStatus("Failed to log in: " + errorMessage)
    setAuthenticated(false)
  }

  function SignupSuccess(user) {
    // User signed up so now we log them in
    setAuthenticated(true)
    setAuthenticatedUser(user)

    // Store that we now remember this person logged in successfully before
    localStorage.setItem('rememberMe', true);
  }

  function SignupFailure(errorMessage) {
    console.log(errorMessage)
    setSignupStatus("Failed to sign up: " + errorMessage)
  }

  return (
    <div>
      <h1 class="center-text" id="Top">Welcome to Zoe & Durbin's Wedding!</h1>
      <h2 class="center-text">14th - 17th July 2025</h2>
      <TabGroup>
        <Tab title="Login">
          <div>
            <LoginForm
              onSuccess={(user)=>{LoginSuccess(user)}}
              onFailure={(errorMessage)=>{LoginFailure(errorMessage)}}
            />
            <p>{loginStatus}</p>
          </div>
        </Tab>
        <Tab title="Signup">
          <div>
            <h3>Please sign up to RSVP</h3>
            <SignupForm
              onSuccess={(user)=>{SignupSuccess(user)}}
              onFailure={(errorMessage)=>{SignupFailure(errorMessage)}}
            />
            <p>{signupStatus}</p>
          </div>
        </Tab>
      </TabGroup>
    </div>
  )
}

export default LoginPage;
