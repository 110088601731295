import React from 'react';
import BackToTop from './BackToTop';
import Map from './Images/MapAlone.jpg';
import ButtonDirections from './Images/ButtonDirections2.jpg';

function Directions () {

  return (
    <div class="center-text">
      <h2 id="Directions"><img src={ButtonDirections} class="subheader" alt="Cartoon Button saying &quot;Directions&quot;"/></h2>
      <div><img src={Map} class="center" alt="Cartoon map of the Braunton area showing wedding location"/></div>
      <h3>By Road</h3>
      <p>Take the A361 through Barnstaple, through the village of Wrafton and into the village of Braunton. After half a mile you will pass Squire Fish & Chips restaurant on the left side of the high street and SQ Bar on the right At the cross roads in the centre of town take a right onto East Street.</p>
      <p>Follow it around for around 300m and you will come to a fork/T junction in the road. Take the middle fork further up the hill (North Down street).</p>
      <p>Follow North Down Rd up the hill and at the fork take the right then immediately the left fork (don’t go up Ash Lane). This is a single track narrow lane but don’t worry it is the right one.</p>
      <p>Follow the road for a mile or so and you will arrive! Find somewhere to park on the large gravel carpark and come find us! We can’t wait!!</p>
      <BackToTop/>
    </div>
  )
}

export default Directions;
