import React from 'react';
import BackToTop from './BackToTop';
import DIYPic from './Images/DIY.jpg';
import ButtonDIY from './Images/ButtonDIY2.jpg';

function DIY () {

  return (
    <div class="center-text">
      <h2 id="DIY"><img src={ButtonDIY} class="subheader" alt="Cartoon Button saying &quot;DIY&quot;"/></h2>
      <div><img src={DIYPic} class="center" alt="Cartoon ensemble of people helping out: Sian and Zoe hold multicoloured bunting, Rohan has a camera, Naps is DJ, Durbin has booze, Kayleigh has a broom, Becca has swag, Charlotte has cookies, Debbie has a cake."/></div>
      <p>Friendship, solidarity & mucking in together will be central to this event.</p>
      <p>Think of it less as a wedding and more of a party where we'll all pitch in together to make the day awesome.</p>
      <p>Whether it's helping pop up decorations, food prep, a turn at the bar or helping with clean up afterwards, your help & contribution to the day will be very much appreciated!</p>
      <BackToTop/>
    </div>
  )
}

export default DIY;
