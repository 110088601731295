import React from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

function LogoutButton () {

  const navigate = useNavigate();

  function Logout() {
    console.log("Logout - clicked")
    localStorage.setItem("authenticated", false);
    localStorage.setItem("authenticateduser", null);
    console.log("Logout - About to navigate")

    axios.post(API_URL + 'auth/logout', "", {withCredentials: true}).then(
      (response) => {
        // Success
        console.log(response)
      },
      (error) => {
        // There was an error
        console.log(error)
      }
    ).catch((error)=>{
      console.log("actual error: ", error)
      this.props.onFailure("unknown error, " + error.message)
    });

    navigate("/login");
  }

  return <button onClick={Logout} class="logout">Logout</button>
}

export default LogoutButton;
