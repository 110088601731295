import React from 'react';
import BackToTop from './BackToTop';
import ButtonFAQ from './Images/ButtonFAQ2.jpg';

function FAQ () {

  return (
    <div class="center-text">
      <h2 id="FAQ"><img src={ButtonFAQ} class="subheader" alt="Cartoon Button saying &quot;FAQ&quot;"/></h2>
      <h3>Accommodation</h3>
      <p>There's plenty of space to camp, including dry pitching in the games barn if the weather is awful, if you want to put your sleeping bag/air mattress on a floor that's also just fine! Ash Barton are very relaxed about sleeping configurations, as long as we're respectful of the space.</p>
      <p>There's also an electric hook up for 3 vehicles.</p>
      <p>The bridal party and those with specific needs will be in the manor house but rooms are limited so this can't be offered to everyone.</p>
      <p>If camping isn't your thing, please book accomodation off site. Due to its lovely coastal location there are plenty of wonderful hotels and Airbnbs not too far from the venue.</p>
      <p>Ash Barton is exclusively ours for the 14th-17th and there's no need to book and/or pay for your stay.</p>
      <h3>Dress Code</h3>
      <p>Wear what you like! We'll be formal for the ceremony, but will likely change soon after! Being comfortable is more important to us than being fancy, but if you want to dress up, go for it!</p>
      <h3>Getting Ready</h3>
      <p>If you're camping there are either getting ready spaces in the 'posh loos' complete with make up mirrors, plugs for styling tools etc, or we'll have a ready room in the manor house everyone can use.</p>
      <h3>Transport</h3>
      <p>If you're staying off site please arrange a taxi in advance. There are a number of local taxi firms if you need help with this, shout!</p>
      <h3>Food</h3>
      <p>We'll be providing breakfast, lunch, dinner, snacks and soak up on the wedding day. If you're staying with us for longer, you're welcome to use the fully equipped kitchen & BBQs, but you'll need to provide your own food for the additional days.</p>
      <h3>Drink</h3>
      <p>The lovely Sally and Andy are providing a stocked bar for the wedding day. (If you see them, give them a hug! ;))  We've guestimated how much we'll need and your drink choice from the RSVP should be behind the bar. Incase we've wildly underestimated how much we need, please bring a back up bottle if you think you'll go hard! (If it doesn't get used, take it home with you!)</p>
      <h3>Gifts</h3>
      <p>We really just want to spend the day with you, that would be the best gift of all, however if you'd like to provide a gift, we'll happily accept money towards our honeymoon.</p>
      <h3>Outline Itinerary</h3>
      <p>Monday - Arrive from midday, lend a hand to help setup, decorate, maybe grab a take away, explore the local beaches or enjoy the pool.</p>
      <p>Tuesday - ceremony and party.</p>
      <p>Wednesday - pack down, hang outs.</p>
      <p>Thursday - home time!</p>
      <h3>Rules</h3>
      <p>Really very few;</p>
      <p>Treat each other and the property with respect</p>
      <p>No smoking inside</p>
      <p>No indoor furniture outside</p>
      <BackToTop/>
    </div>
  )
}

export default FAQ;
