import React from 'react';
import { Navigate } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

function RequireAuth({ children }) {

  const navigate = useNavigate();
  let authenticated = localStorage.getItem("authenticated") === 'true';

  useEffect(() => {
    const stillActiveSession = () => {
      const loggedIn = localStorage.getItem("authenticated") === 'true';

      if (loggedIn) {
        axios.post(API_URL + 'auth/validate', "", {withCredentials: true}).then(
          (response) => {
            // Success - do nothing, user stays logged in

          },
          (error) => {
            // There was an error
            localStorage.setItem("authenticated", false);
            localStorage.setItem("authenticateduser", null);
            navigate("/login");
          }
        ).catch((error)=>{
          console.log("actual error: ", error)
          this.props.onFailure("unknown error, " + error.message)
          localStorage.setItem("authenticated", false);
          localStorage.setItem("authenticateduser", null);
          navigate("/login");
        });
      }
    }

    let timeChecker = () => {
      // Check we are logged in every 30 mins
      setInterval(() => {
        stillActiveSession()
      }, 30 * 60 * 1000);
    };

    // Check right now if the session is logged in
    stillActiveSession()

    // Set a timer to check the session is logged in
    timeChecker();

  }, [authenticated, navigate]);

  const location = useLocation();

  return authenticated === true ? children : <Navigate to="/login" replace state={{ path: location.pathname }} />;
}

export default RequireAuth;
