import React, { Component } from 'react';
//import ReactTable from 'react-table-6';
//import 'react-table-6/react-table.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from './LoginPage';
import Dashboard from './Dashboard';
import RequireAuth from './RequireAuth';
import About from './About';
import Directions from './Directions';
import DIY from './DIY';
import Menu from './Menu';
import Title from './Title';
import ZoeDurbin from './ZoeDurbin';
import LogoutButton from './LogoutButton';
import FAQ from './FAQ';

class WeddingSite extends Component {

  render() {

    return (
      <div class="white-background">
        <BrowserRouter>
          <Routes>
            <Route path="" element={<LoginPage />} />
            <Route path="login" element={<LoginPage />} />
            <Route
              path="dashboard"
              element={
                <RequireAuth>
                  <LogoutButton />
                  <Title/>
                  <Menu />
                  <ZoeDurbin />
                  <About />
                  <DIY />
                  <Directions />
                  <Dashboard />
                  <FAQ />
                </RequireAuth>
              } />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default WeddingSite;
