import './App.css';
import WeddingSite from './WeddingSite';

function App() {
  return (
    <div className="App">
    <WeddingSite />
    </div>
  );
}

export default App;
