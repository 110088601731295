import React from 'react';
import { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';
import * as Yup from "yup";
import YupPassword from 'yup-password'
import BackToTop from './BackToTop';
import ButtonRSVP from './Images/ButtonRSVP2.jpg';
YupPassword(Yup) // extend yup

const API_URL = process.env.REACT_APP_API_URL;

const schema = Yup.object().shape({
  names: Yup.string().max(500),
  attending: Yup.boolean().default(false),
  song: Yup.string().max(500),
  diet: Yup.string().max(500),
  drink: Yup.string().max(500)
});

function DashboardForm () {

  const [authenticatedUser] = useState(JSON.parse(localStorage.getItem("authenticateduser")));
  const [updateStatus, setUpdateStatus] = useState("");

  const updateUserRequest = (values) => {
    const email = authenticatedUser.Email
    const names = values.names
    const attending = values.attending
    const drink = values.drink
    const diet = values.diet
    const song = values.song

    // Update our local storage user
    let updatedUser = JSON.parse(localStorage.getItem("authenticateduser"))
    updatedUser.Names = names
    updatedUser.Attending = attending
    updatedUser.Drink = drink
    updatedUser.Diet = diet
    updatedUser.Song = song
    localStorage.setItem("authenticateduser", JSON.stringify(updatedUser));

    // Update the database
    axios.post(API_URL + 'guest/update', { email, names, attending, drink, diet, song }, {withCredentials: true}).then(
      (response) => {
        // Success
        setUpdateStatus("successfully updated details")
      },
      (error) => {
        // There was an error
        setUpdateStatus("Failed to update details: " + error.response.data.message)
      }
    ).catch((error)=>{
      console.log("actual error: ", error)
    });
  }

  console.log("DashboardForm - authenticatedUser", JSON.parse(localStorage.getItem("authenticateduser")))

  return (
    <div>
      <h2 class="center-text" id="RSVP"><img src={ButtonRSVP} class="subheader" alt="Cartoon Button saying &quot;RSVP&quot;"/></h2>
      <div class="boxout">
        <p>User : {authenticatedUser && authenticatedUser.Email ? authenticatedUser.Email : "Loading..."}</p>
        <Formik

          // This allows the form to refresh when values are changed
          enableReinitialize={true}

          initialValues={{
           names: authenticatedUser && authenticatedUser.Names ? authenticatedUser.Names : "",
           attending: authenticatedUser && authenticatedUser.Attending ? "1" : "0",
           drink: authenticatedUser && authenticatedUser.Drink ? authenticatedUser.Drink : "",
           diet: authenticatedUser && authenticatedUser.Diet ? authenticatedUser.Diet : "",
           song: authenticatedUser && authenticatedUser.Song ? authenticatedUser.Song : "",
         }}
          validationSchema={schema}

          onSubmit={(values, { setSubmitting }) => {
            updateUserRequest(values);
            setSubmitting(false);
          }}
        >
        {({ isSubmitting, values }) => (
          <Form>
            <p>
              <label for="names">Name(s)</label>
              <Field type="text" name="names" />
              <ErrorMessage name="names" component="div" />
            </p>
            <p>
              Attending
              <label class="attending-yes">
                <Field type="radio" name="attending" value="1" />
                Yes
              </label>
              <label class="attending-no">
                <Field type="radio" name="attending" value="0" />
                No
              </label>
              <ErrorMessage name="attending" component="div" />
            </p>
            <p>
              <label for="Drink">Drink of choice</label>
              <Field type="text" name="drink" />
              <ErrorMessage name="drink" component="div" />
            </p>
            <p>
              <label for="Diet">Do you have any allergies or restrictions we should know about?</label>
              <Field type="text" name="diet" />
              <ErrorMessage name="diet" component="div" />
            </p>
            <p>
              <label for="Song">What song will get you on the dance floor?</label>
              <Field type="text" name="song" />
              <ErrorMessage name="song" component="div" />
            </p>
            <p>
              <button type="submit" disabled={isSubmitting}>
                Submit
              </button>
            </p>
          </Form>
          )}
        </Formik>
        <p>{updateStatus}</p>
      </div>
      <BackToTop/>
    </div>
  )
}

export default DashboardForm;
