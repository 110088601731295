import React from 'react';
import TitleImage from './Images/Title.jpg';

function Title () {

  return (
    <div class="center-text">
      <img src={TitleImage} class="center" alt="Cartoon saying &quot;Zoe & Durbin's Wedding! 14th-17th July 2025&quot;"/>
    </div>
  )
}

export default Title;
