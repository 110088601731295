import React from 'react';
import ZoeDurbinImage from './Images/Zoe+Durbin.jpg';

function ZoeDurbin () {

  return (
    <div class="center-text">
      <img src={ZoeDurbinImage} class="center" alt="Cartoon of Zoe and Durbin"/>
    </div>
  )
}

export default ZoeDurbin;
