import React from 'react';

function BackToTop () {

  return (
    <div class="center center-text">
      <p><a href="#Top">Back to top</a></p>
    </div>
  )
}

export default BackToTop;
